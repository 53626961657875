import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO title="Treat Heel Pain, Tendonitis, and Other Foot Conditions" description="Suffering from bunions, Achilles tendonitis, plantar fasciitis, or some other kind of foot, ankle, or heel pain? Memphis podiatrist Dr. Butler can help. View services now."/>
    <section className="blue">
      <div className="services">
        <h1>Podiatrist Services</h1>
        <h2>Board-qualified in reconstructive foot and ankle surgery, Dr.&nbsp;Murray Butler can help you with the following conditions.</h2>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" className="border" viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path className="blue" d="M0,160L80,160C160,160,320,160,480,154.7C640,149,800,139,960,133.3C1120,128,1280,128,1360,128L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
    </svg>
    <section className="conditions">
      <ul id="column-one">
        <li><Link to="/conditions/accessory-navicular-syndrome/">Accessory Navicular Syndrome</Link></li>
        <li><Link to="/conditions/achilles-tendon-rupture/">Achilles Tendon Ruptures</Link></li>
        <li><Link to="/conditions/achilles-tendonitis-tendonosis/">Achilles Tendonitis &amp; Tendonosis</Link></li>
        <li><Link to="/conditions/ankle-fracture/">Ankle Fractures</Link></li>
        <li><Link to="/conditions/ankle-sprain/">Ankle Sprain</Link></li>
        <li><Link to="/conditions/athletes-foot/">Athlete's Foot</Link></li>
        <li><Link to="/conditions/bunions-hallux-abducto-valgus/">Bunions</Link></li>
        <li><Link to="/conditions/calcaneus-heel-bone-fractures/">Calcaneus Fractures</Link></li>
        <li><Link to="/conditions/capsulitis-second-toe/">Capsulitis</Link></li>
        <li><Link to="/conditions/cavus-foot/">Cavus Foot</Link></li>
        <li><Link to="/conditions/charcot-foot/">Charcot Foot</Link></li>
        <li><Link to="/conditions/chronic-ankle-instability/">Chronic Ankle Instability</Link></li>
        <li><Link to="/conditions/compartment-syndrome/">Compartment Syndrome</Link></li>
        <li><Link to="/conditions/deep-vein-thrombosis/">Deep Vein Thrombosis</Link></li>
        <li><Link to="/conditions/diabetic-complications-amputation-prevention/">Diabetic Complications</Link></li>
        <li><Link to="/conditions/diabetic-peripheral-neuropathy/">Diabetic Peripheral Neuropathy</Link></li>
        <li><Link to="/conditions/fifth-metatarsal-facture/">Fifth Metatarsal Fractures</Link></li>
        <li><Link to="/conditions/ganglion-cyst/">Ganglion Cyst</Link></li>
        <li><Link to="/conditions/gout/">Gout</Link></li>
        <li><Link to="/conditions/haglunds-deformity/">Haglund's Deformity</Link></li>
        <li><Link to="/conditions/hallux-rigidus-toe-pain/">Hallux Rigidus (Toe Pain)</Link></li>
        <li><Link to="/conditions/hammertoe/">Hammertoe</Link></li>
        <li><Link to="/conditions/plantar-fasciitis-heel-pain/">Heel Pain (Plantar Fasciitis)</Link></li>
      </ul>
      <ul id="column-two">
        <li><Link to="/conditions/ingrown-toenail/">Ingrown Toenail</Link></li>
        <li><Link to="/conditions/lisfranc-injuries/">Lisfranc Injuries</Link></li>
        <li><Link to="/conditions/mortons-neuroma-intermetatarsal-neuroma/">Morton's Neuroma</Link></li>
        <li><Link to="/conditions/os-trigonum-syndrome/">Os Trigonum Syndrome</Link></li>
        <li><Link to="/conditions/osteoarthritis/">Osteoarthritis</Link></li>
        <li><Link to="/conditions/pediatric-flatfoot/">Pediatric Flatfoot</Link></li>
        <li><Link to="/conditions/peripheral-arterial-disease/">Peripheral Arterial Disease</Link></li>
        <li><Link to="/conditions/peroneal-tendon-injuries/">Peroneal Tendon Injuries</Link></li>
        <li><Link to="/conditions/plantar-fibroma/">Plantar Fibroma</Link></li>
        <li><Link to="/conditions/plantar-wart-verruca-plantaris/">Plantar Wart</Link></li>
        <li><Link to="/conditions/posterior-tibial-tendon-dysfunction/">Posterior Tibial Tendon Dysfunction</Link></li>
        <li><Link to="/conditions/raynaud-phenomenon/">Raynaud's Phenomenon</Link></li>
        <li><Link to="/conditions/rheumatoid-arthritis/">Rheumatoid Arthritis</Link></li>
        <li><Link to="/conditions/sesamoid-injuries/">Sesamoid Injuries</Link></li>
        <li><Link to="/conditions/calcaneal-apophysitis-severs-disease/">Sever's Disease</Link></li>
        <li><Link to="/conditions/shin-splints/">Shin Splints</Link></li>
        <li><Link to="/conditions/stress-fractures/">Stress Fractures</Link></li>
        <li><Link to="/conditions/tailors-bunion-bunionette/">Tailor's Bunion</Link></li>
        <li><Link to="/conditions/talar-dome-lesion/">Talar Dome Lesion</Link></li>
        <li><Link to="/conditions/tarsal-coalition/">Tarsal Coalition</Link></li>
        <li><Link to="/conditions/tarsal-tunnel-syndrome/">Tarsal Tunnel Syndrome</Link></li>
        <li><Link to="/conditions/broken-toes-metatarsal-fractures/">Toe and Metatarsal Fractures</Link></li>
        <li><Link to="/conditions/turf-toe/">Turf Toe</Link></li>
      </ul>
    </section>
  </Layout>
)

export default ServicesPage
